const data = [
  {
    id: 1,
    image: "https://img.icons8.com/ios/64/000000/menu.png",
    title: "Posts",
    desc: "Streamline the orders with our user-friendly Order List tab.",
  },
  {
    id: 2,
    image: "https://img.icons8.com/ios/40/000000/open-book.png",
    title: "Students",
    desc: " Streamline the Carts with our user-friendly Cart List tab.",
  },
  {
    id: 3,
    image:
      "https://img.icons8.com/fluency-systems-regular/64/000000/retro-tv.png ",
    title: "Track Users",
    desc: "Track Payment status, Delivery status here.",
  },
  {
    id: 4,
    image: "https://img.icons8.com/material-outlined/64/000000/topic--v1.png ",
    title: "Notifications",
    desc: "Notify status to students here.",
  },
  {
    id: 5,
    image: "https://img.icons8.com/ios-filled/64/000000/wifi.png ",
    title: "Users Traffic",
    desc: "Detailed Report of all users and its traffic reports.",
  },
  {
    id: 6,
    image: "https://img.icons8.com/glyph-neue/50/000000/polyline.png ",
    title: "Users Charts",
    desc: " Charts based on users history.",
  },
  {
    id: 7,
    image: "https://img.icons8.com/metro/52/000000/add-ticket.png",
    title: "Awards & ECA",
    desc: "Streaming Awards& ECA  Data",
  },
];
export default data;
