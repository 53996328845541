import React, { useEffect, useState } from "react";
import * as Components from "./Components";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLoginSession } from "../../redux/slices/loginSessionSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axiosInstance from "../../http-common";

function Login() {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const user = useSelector((state: any) => state.loginDetails);
  // const [showPass, setShowPass] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    userType: "Admin",
  });
  const [signIn, toggle] = useState<any>(true);
  const [rememberMe, setRememberMe] = useState(false);
  const path = location.pathname;
  console.log(path);
  const state = localStorage.getItem("accessToken");

  useEffect(() => {
    const storedFormData = Cookies.get("formData");
    const storedRememberMe = Cookies.get("rememberMe");
    if (storedFormData || storedRememberMe) {
      Cookies.remove("formData");

      Cookies.remove("rememberMe");
    }

    if (storedRememberMe === "true" && storedFormData) {
      setFormData(JSON.parse(storedFormData));
      setRememberMe(true);
    }
    if (user && user?.data?.accessToken) {
      const accessToken = user?.data?.accessToken;
      const refreshToken = user.data.refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    }

    if (user && user.isLoggedIn) {
      navigate("../dashboard");
    }
  }, [navigate, user, state, dispatch,]);

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };


  const handleLogin = async (event: any) => {
    if (!formData.email) {
      toast.error("Please Enter Email Address");
    }
    if (!formData.password) {
      toast.error("Please Enter Password");
    }
    event.preventDefault();
    Cookies.set("rememberMe", rememberMe ? "true" : "false", { expires: 365 });
    if (rememberMe) {
      Cookies.set("formData", JSON.stringify(formData), { expires: 365 });
    }
    if (formData.password && formData.email ) {
      const logData = {
        email: formData.email,
        password: formData.password,
        userType: "Admin",
      };
      try {
        dispatch(getLoginSession(logData))
          .then(() => {
            navigate("../dashboard");
          })
          .catch((error: any) => {
            console.error("Error dispatching action:", error);
          });
      } catch (error: any) {
        // 50x code will come here
        if (error.response && error.response.data) {
          console.error("Error LogResponse in user:", error.response.data);
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error Logging in user:", error.message);
          toast.error(error.message);
        }
      }
    }
  };


 


  const handleRegister = async (event: any) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
    }
    if (formData.email === "") {
      toast.error("Please Enter your email Address");
    } else {
      const regData = {
        email: formData.email,
        password: formData.password,
        userType: "Admin",
      };

      try {
        await axiosInstance.post("/api/admin/register", regData);
        await axiosInstance.post("/api/admin/sendverifyemail", regData);
        toast.success("User created successfully");
        toast.success("Verfication email successfully");
        navigate("../success-register", { state: formData.email });
      } catch (error: any) {
        console.log(error.response.data.errorMessage);
        if (error.response && error.response.data) {
          console.error(
            "Error creating User:",
            error.response.data.errorMessage
          );
          toast.error(error.response.data.errorMessage);
        } else {
          console.error("Error creating User:", error.message);
          toast.error(error.message);
        }
      }
    }
  };


  return (
    <>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: "100px 0px 0px 0px",
        }}
      >
        <Components.Container>
          <Components.SignUpContainer signinIn={signIn}>
            <Components.Form>
              <Components.Title>Create Account</Components.Title>
              <Components.Input
                type="email"
                placeholder="email"
                value={formData.email}
                onChange={handleInputChange}
                name="email"
              />
              <Components.Input
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                name="password"
              />
              <Components.Input
                type="password"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                name="confirmPassword"
              />
              <Components.Button onClick={handleRegister}>
                Sign Up
              </Components.Button>
            </Components.Form>
          </Components.SignUpContainer>

          <Components.SignInContainer signinIn={signIn}>
            <Components.Form>
              <Components.Title>Sign in</Components.Title>
              <Components.Input
                type="email"
                placeholder="email"
                value={formData.email}
                onChange={handleInputChange}
                name="email"
              />
              <Components.Input
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                name="password"
              />
              {/* <Components.Anchor href="#">
                Forgot your password?
              </Components.Anchor> */}
              <Components.Button onClick={handleLogin}>
                Sign In
              </Components.Button>
            </Components.Form>
          </Components.SignInContainer>

          <Components.OverlayContainer signinIn={signIn}>
            <Components.Overlay signinIn={signIn}>
              <Components.LeftOverlayPanel signinIn={signIn}>
                <Components.Title>Welcome Back!</Components.Title>
                <Components.Paragraph>
                  To keep connected with us please login with your personal info
                </Components.Paragraph>
                <Components.GhostButton onClick={() => toggle(true)}>
                  Sign In
                </Components.GhostButton>
              </Components.LeftOverlayPanel>

              <Components.RightOverlayPanel signinIn={signIn}>
                <Components.Title>Hello, Admin!</Components.Title>
                <Components.Paragraph>
                  Enter Your Credentials and start journey with us
                </Components.Paragraph>
                {/* <Components.GhostButton onClick={() => toggle(false)}>
                  Sigin Up
                </Components.GhostButton> */}
              </Components.RightOverlayPanel>
            </Components.Overlay>
          </Components.OverlayContainer>
        </Components.Container>
      </Grid>
    </>
  );
}

export default Login;
