import React from 'react'
import Coupon from '../../components/Coupon/Coupon'

const AddCoupon = () => {
  return (
    <>
      <Coupon/>
    </>
  )
}

export default AddCoupon