export const navItems = [
  "Home",
  "Students",
  "Track Users",
  "Notifications",
  "User Traffic",
  "User Charts",
  "Awards",
];
export const menuItems = [
  "Order Mgt.",
  "Offer Mgt.",
  "Inventory Mgt.",
  "Product Mgt.",
  "Administration",
  "Reports",
  "Cart Mgt.",
];
export const subOrders = [
  "Home",
  "New Orders",
  "Active Orders",
  "Completed Orders",
  "Cancelled Orders",
  "Orders Report",
];
export const subCarts = ["Home", "Carts List", "Remainder Schedule"];
export const subInventary = ["Home", "Products available", "Ordered Product List"];
export const subProducts = ["Home", "Strapi Products", "Product Campaigns"];
export const subAdministration = [
  "Home",
  "Role Creation",
  "User Creation",
  "pages Previllage",
  "user Previllage",
  "Notification config",
  "Shop Mainatanence",
];
export const subReports = [
  "Home",
  "User History Report",
  "User Actions report",
  "Order Mgmt Report",
  "Shop report",
  "Notification report",
];
export const subOffers = ["Home", "Add Coupon", "Existing Coupon", "Special Offers"];
