import React, { useEffect, useState } from "react";
import http from "../../http-common";
import { AppBar, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";

interface OrderItem {
  productName: string;
}

interface Order {
  _id: string;
  orderItems: OrderItem[];
}

const FullOrdersReport: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data: Order[] = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const productCountList: { [key: string]: number } = {};
  orders.forEach((order) => {
    order.orderItems.forEach((item) => {
      const productName = item.productName;
      if (!productCountList[productName]) {
        productCountList[productName] = 1;
      } else {
        productCountList[productName]++;
      }
    });
  });

  const uniqueProductNames: string[] = Object.keys(productCountList).slice(1);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div>FullOrdersReport</div>
      <AppBar position="static"></AppBar>
      <Grid container>
        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={uniqueProductNames.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>
                  S.No
                </TableCell>
                <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>
                  Product Name
                </TableCell>
                <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>
                  Product Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniqueProductNames.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((productName, index) => (
                <TableRow key={productName}>
                   <TableCell style={{ textAlign: "center" }}>
                     {index + page * rowsPerPage + 1}
                    </TableCell>    
                  <TableCell style={{ textAlign: "center" }}>{productName}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{productCountList[productName]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
export default FullOrdersReport