import React from "react";
import FullWidthTabs from "./FullWidthTabs";

const ActiveOrders = () => {
  return (
    <>
<FullWidthTabs/>
    </>
  );
};

export default ActiveOrders;
