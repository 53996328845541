import React, { useState } from "react";
import Card from "./Cards";
import data from "./Data";
import "./features.css";
import Orders from "../Posts/Posts";
import Carts from "../Carts/Carts";
import Status from "../Status/Status";
import Notify from "../Notify/Notify";
import Traffic from "../Traffic/Traffic";
// import LineCharts from "../Charts/LineCharts";
import BarCharts from "../Charts/BarCharts";
import Coupon from "../Coupon/Coupon";
import { Grid } from "@mui/material";
const Features = () => {
  const [page, setPage] = useState(null);

  return (
    <>
      {page === 0 && (
        <>
          <Orders />
        </>
      )}
      {page === 1 && (
        <>
          <Carts />
        </>
      )}
      {page === 2 && (
        <>
          <Status />
        </>
      )}
      {page === 3 && (
        <>
          <Notify />
        </>
      )}
      {page === 4 && (
        <>
          <Traffic />
        </>
      )}
      {page === 5 && <>{/* <LineCharts /> */}</>}
      {page === 5 && (
        <>
          <BarCharts />
        </>
      )}
      {page === 7 && (
        <>
          <Coupon />
        </>
      )}

      {page === null && (
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Paper sx={{ p: "10px 10px 10px 10px", background: "white" }}>
                <LineCharts />
              </Paper>
            </Grid> */}
          </Grid>
          <section className="features top" id="features">
            <div className="container">
              <div className="heading">
                <h4>The Melo Vault Dashboard</h4>
                <h1>Dashboard</h1>
              </div>

              <div className="content grid">
                {data.map((val: any, index: any) => {
                  return (
                    <div key={index} onClick={() => setPage(index)}>
                      <Card
                        key={index}
                        image={val.image}
                        title={val.title}
                        desc={val.desc}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Features;
