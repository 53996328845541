import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { toast } from "react-toastify";
import http from "../../http-common";
import { useNavigate } from "react-router-dom";
import constants from "../../constants/constant";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Posts = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<any>(null);
  const [formData, setFormData] = useState({
    image: image,
    tags: [],
    title: "",
    description: "",
    link: "",
  });

  const handleInputChange = (e: any) => {
        
    const { name, value } = e.target;

    if (name === "tags") {
      const tagsArray = value.split(",").map((tag: any) => tag.trim());
      setFormData({ ...formData, image:image, [name]: tagsArray });
    } else {
      setFormData({ ...formData, image:image, [name]: value });
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    console.log(event.target.files);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    setImage(null);
  };

  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      // const response = await http.post("/api/studentform", formData);
      if (image) {
        const imageResponse = await http.post(
          "/api/admin/post/upload",
          formData
        );
        console.log(imageResponse);
      }
      // console.log(response);

      toast.success("Post uploaded Successfully");
      navigate("../dashboard");
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <div>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "20px 0px 0px 0px",
        }}
      >
        <Paper
          sx={{
            background: "white",
            width: "500px",
            height: "auto",
            p: "0px 0px 20px 0px",
          }}
          elevation={2}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px 0px 0px 0px",
            }}
          >
            {!image && (
              <>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <InsertPhotoIcon
                    sx={{
                      fontSize: "100px",
                      background: "white",
                      color: "#cccccc",
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
              </>
            )}
            {image && (
              <>
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </>
            )}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              p: "20px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                p: "10px 0px 0px 0px",
              }}
            >
              {!image && (
                <>
                  <Button
                    size="small"
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      fontSize: "8px",
                      backgroundColor: constants.tertiary,
                      color: "white",
                    }}
                  >
                    Upload Photo
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                </>
              )}
              {image && (
                <>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      p: "10px 0px 0px 0px",
                    }}
                  >
                    <Grid>
                      <Button
                        component="label"
                        variant="outlined"
                        sx={{ fontSize: "8px", background: constants.tertiary }}
                        fullWidth
                        onClick={handleRemove}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Tags:(seperate tags by coma)*
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <TextField
                size="small"
                id="outlined-multiline-flexible"
                label=""
                value={formData.tags}
                name="tags"
                multiline
                rows={1}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>Title:*</Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <TextField
                size="small"
                id="outlined-multiline-flexible"
                label=""
                value={formData.title}
                name="title"
                multiline
                rows={1}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>Description:*</Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <TextField
                size="small"
                id="outlined-multiline-flexible"
                label=""
                value={formData.description}
                name="description"
                multiline
                rows={1}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>Website Link:*</Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <TextField
                size="small"
                id="outlined-multiline-flexible"
                label=""
                value={formData.link}
                name="link"
                multiline
                rows={1}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Button
              component="label"
              variant="contained"
              sx={{
                backgroundColor: constants.tertiary,
                color: "white",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default Posts;
