import axios from "axios";

const API_ENDPOINT = "https://api.linkpreview.net";
const API_KEY = "4e2a9df12fc9e34568cf9f0d12861105";

interface LinkPreviewResponse {
  title: string;
  description: string;
  image: string;
  url: string;
}

export const fetchLinkPreview = async (
  url: string
): Promise<LinkPreviewResponse> => {
  try {
    const response = await axios.get(API_ENDPOINT, {
      params: {
        key: API_KEY,
        q: url,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching link preview:", error);
    throw error;
  }
};
