import React, { useEffect, useState } from "react";
import http from "../../http-common";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

interface OrderItem {
  productName: string;
  shape: string;
  eggless: boolean;
  productId: number;
}

interface Order {
  _id: string;
  orderItems: OrderItem[];
}

const ShopReport: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/orders");
        const data: Order[] = response.data;
        setOrders(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const productCountList: { [key: string]: number } = {};
  const eggCakeCountList: { [key: string]: number } = {};
  const totalCount: { [key: string]: number } = {};

  orders.forEach((order) => {
    order.orderItems.forEach((item) => {
      const productName = item.productName;
      const isEggless = item.eggless;

      const key = `${productName}`;
      if (!productCountList[key]) {
        productCountList[key] = 1;
      } else {
        productCountList[key]++;
      }

      if (isEggless) {
        if (!eggCakeCountList[key]) {
          eggCakeCountList[key] = 0;
        }
      } else {
        if (!eggCakeCountList[key]) {
          eggCakeCountList[key] = 1;
        } else {
          eggCakeCountList[key]++;
        }
      }
      totalCount[key] = productCountList[key] + eggCakeCountList[key];
    });
  });

  const uniqueProductKeys: string[] = Object.keys(productCountList).slice(1);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
    <div> ShopReport </div>
      <TableContainer>
      <TablePagination
                rowsPerPageOptions={[15, 25, 50]}
                component="div"
                count={uniqueProductKeys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        <Table>
          <TableHead>   
            <TableRow>
            <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}> S.No</TableCell>
              <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>Product Name</TableCell>
              <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>Shape</TableCell>
              <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>Eggless Cakes</TableCell>
              <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>Egg Cakes</TableCell>
              <TableCell style={{ textAlign: "center" }} sx={{ fontWeight: "bold" }}>Total Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueProductKeys
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((key, index) => {
                const matchingOrder = orders.find((order) => {
                  const matchingItem = order.orderItems.find(
                    (item) => item.productName === key
                  );
                  return matchingItem;
                });

                if (matchingOrder) {
                  const matchingItem = matchingOrder.orderItems.find(
                    (item) => item.productName === key
                  );

                  if (matchingItem) {
                    return (
                      <TableRow key={key}>
                        <TableCell style={{ textAlign: "center" }}>
                     {index + page * rowsPerPage + 1}
                    </TableCell>    
                        <TableCell style={{ textAlign: "center" }}>{key.split("-")[0]}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>{matchingItem.shape}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>{productCountList[key]}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>{eggCakeCountList[key]}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>{totalCount[key]}</TableCell>
                      </TableRow>
                    );
                  }
                }
                return null;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      </>
  );
};

export default ShopReport;
