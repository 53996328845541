import * as React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import constants from "../../constants/constant";

const AccountPopOver = () => {

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
  };
    
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button
            sx={{ background: constants.tertiary }}
            variant="contained"
            {...bindTrigger(popupState)}
          >
            <AccountCircleIcon />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ background: "white", color: "black" }}
              onClick={handleLogOut}
            >
              LogOut
            </Button>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default AccountPopOver;
