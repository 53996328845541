import  {  useEffect, useState } from "react";
import { fetchLinkPreview } from "../../services/linkPreviewService";
import {
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import constants from "../../constants/constant";
import { toast } from "react-toastify";
import http from "../../http-common";

interface LinkPreviewProps {
  url: string;
  setUrl: any;
}

const LinkPreview: React.FC<LinkPreviewProps> = ({ url, setUrl }) => {
  const [linkPreview, setLinkPreview] = useState<any>(null);
  const [formData, setFormData] = useState<any>({ ...linkPreview });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getLinkPreview = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchLinkPreview(url);
        setLinkPreview(data);
        setFormData(data)
      } catch (err) {
        setError("Failed to fetch link preview");
      } finally {
        setLoading(false);
      }
    };

    getLinkPreview();
  }, [url]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!linkPreview) return null;

  console.log(linkPreview);




  const truncateString = (str: string, num: number): string => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const shortDesc = truncateString(linkPreview.description, 70);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "tags") {
      const tagsArray = value.split(",").map((tag: any) => tag.trim());
      setFormData({ ...linkPreview, [name]: tagsArray });
    } else {
      setFormData({ ...linkPreview, [name]: value });
    }
  };

    console.log(formData);
  const handleCancel = () => {
    setUrl("");
  };
  const handleSave = async (event: any) => {
    event.preventDefault();

    try {
      const response = await http.post("/api/admin/link-post/upload", formData);
      console.log(response);

      toast.success("Post uploaded Successfully");
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error("Error LogResponse in user:", error.response.data);
        toast.error(error.response.data.errorMessage);
      } else {
        console.error("Error Logging in user:", error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="link-preview">
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "20px 0px 0px 0px",
        }}
      >
        <Paper
          sx={{
            background: "white",
            width: "500px",
            height: "auto",
            p: "0px 0px 20px 0px",
          }}
          elevation={2}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px 0px 20px 0px",
            }}
          >
            <img
              src={linkPreview.image}
              alt={linkPreview.title}
              style={{ width: "50%" }}
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px 20px 0px 20px",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Title: {linkPreview.title}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "0px 20px 0px 20px",
            }}
          >
            <Typography>Description: {shortDesc}</Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "20px 20px 0px 20px",
            }}
          >
            <Link
              sx={{ color: constants.tertiary }}
              href={linkPreview.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link: {linkPreview.url}
            </Link>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              p: "0px 0px 0px 0px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Tags:(seperate tags by coma)*
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-start",
                p: "5px 20px 5px 20px",
              }}
            >
              <TextField
                size="small"
                id="outlined-multiline-flexible"
                label=""
                value={formData.tags}
                name="tags"
                multiline
                rows={1}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: "20px 20px 0px 0px",
            }}
          >
            <Grid sx={{ p: "0px 20px 0px 0px" }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: constants.seconday }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                sx={{ backgroundColor: constants.tertiary }}
                onClick={handleSave}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default LinkPreview;
