import React, { useState } from "react";
import LinkPreview from "../LinkPreview/LinkPreview";
import { Grid } from "@mui/material";

const LinkLayout: React.FC = () => {
  const [url, setUrl] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  return (
    <div className="App">
      <h1>Enter Link</h1>
      <Grid>
        <input
          type="text"
          value={url}
          onChange={handleInputChange}
          placeholder="Enter a URL"
        />
      </Grid>
      {url && <LinkPreview url={url} setUrl={setUrl} />}
    </div>
  );
};

export default LinkLayout;
