import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../http-common";

export interface studentDataSlice {
  data: {} | null;
  isDataFetched: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: studentDataSlice = {
  data: {},
  isDataFetched: false,
  loading: false,
  error: "",
};

export const getStudentAwards: any = createAsyncThunk("studentAwards", async () => {
  try {
    const res = await axiosInstance.get("/api/awards/all");
    return res.data.response.data;
  } catch (err: any) {
    if (err.response.data.errorMessage) {
      console.log(err.response.data.errorMessage);
      toast.error(err.response.data.errorMessage);
      throw new Error(err.response.data.errorMessage);
    }
    if (err.response.data.errMsg) {
      console.log(err.response.data.errMsg);
      toast.error(err.response.data.errMsg);
      throw new Error(err.response.data.errMsg);
    } else {
      console.log(err);
      throw new Error(err);
    }
  }
});

export const studentAwardsSlice = createSlice({
  name: "studentAwards",
  initialState,
  reducers: {
    remove: (state, action) => {
      state.data = {};
      state.isDataFetched = false;
      state.loading = false;
      state.error = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStudentAwards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStudentAwards.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.isDataFetched = true;
        state.data = action.payload;
      })
      .addCase(getStudentAwards.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isDataFetched = false;
        state.error = action.payload;
        state.data = {};
      });
  },
});

export const { remove } = studentAwardsSlice.actions;
export default studentAwardsSlice.reducer;
