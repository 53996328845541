import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
// import Orders from "./components/Orders/Orders";
// import Carts from "./components/Carts/Carts";
// import Notify from "./components/Notify/Notify";
// import Status from "./components/Status/Status";
// import Traffic from "./components/Traffic/Traffic";
// import Charts from "./components/Charts/Charts";
// import Coupon from "./components/Coupon/Coupon";
import MiniDrawer from "./components/layouts/MiniDrawer";
import Login from "./components/Login/Login";
import setAuthToken from "./common/setAuthToken";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constants from "./constants/constant";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: constants.primary,
    },
    background: {
      default: constants.seconday, 
    },
  },
});

function App() {
  const auth = localStorage.accessToken;

  if (auth) {
    setAuthToken(auth);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/dashboard" element={<MiniDrawer />}></Route>
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
