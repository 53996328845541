import React from 'react'
import Orders from './newOrderComponents/Orders'

const NewOrders = () => {
  return (
    <div>
      <Orders/>
    </div>
  )
}

export default NewOrders