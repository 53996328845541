import React, { useState, useContext, useEffect } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import {
  navItems,
  subOrders,
  subInventary,
  subCarts,
  subProducts,
  subAdministration,
  subReports,
  subOffers,
} from "../../constants/config";
import Features from "../Features/Features";
import Status from "../Status/Status";
import Notify from "../Notify/Notify";
import Traffic from "../Traffic/Traffic";
import Coupon from "../Coupon/Coupon";
import HomeIcon from "@mui/icons-material/Home";
import ReorderIcon from "@mui/icons-material/Reorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TrafficIcon from "@mui/icons-material/Traffic";
import InsightsIcon from "@mui/icons-material/Insights";
import RedeemIcon from "@mui/icons-material/Redeem";
import GradingIcon from "@mui/icons-material/Grading";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { Grid } from "@mui/material";
// import PopOver from "./PopOver";
import { MyContext } from "../../common/MyContext";
import LocalOffer from "@mui/icons-material/LocalOffer";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import DvrIcon from "@mui/icons-material/Dvr";
import SortIcon from "@mui/icons-material/Sort";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import CampaignIcon from "@mui/icons-material/Campaign";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import StoreIcon from "@mui/icons-material/Store";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import BadgeIcon from "@mui/icons-material/Badge";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DoorbellIcon from "@mui/icons-material/Doorbell";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import NewOrders from "../../menuComponents/ordersMenu/NewOrders";
import ActiveOrders from "../../menuComponents/ordersMenu/ActiveOrders";
import CompletedOrders from "../../menuComponents/ordersMenu/CompletedOrders";
import CancelledOrders from "../../menuComponents/ordersMenu/CancelledOrders";
import OrdersReport from "../../menuComponents/ordersMenu/OrdersReport";
import AddCoupon from "../../menuComponents/offersMenu/Coupon";
import ExistingCoupon from "../../menuComponents/offersMenu/ExistingCoupon";
import SpecialOffers from "../../menuComponents/offersMenu/SpecialOffers";
import ProductAvailable from "../../menuComponents/inventeryMenu/ProductAvailable";
import OrderedProductList from "../../menuComponents/inventeryMenu/OrderedProductList";
import StrapiProducts from "../../menuComponents/productMenu/StrapiProducts";
import ProductCampaign from "../../menuComponents/productMenu/ProductCampaign";
import RoleCreation from "../../menuComponents/administrationMenu/RoleCreation";
import UserCreation from "../../menuComponents/administrationMenu/UserCreation";
import PagesPrevilage from "../../menuComponents/administrationMenu/PagesPrevilage";
import UserPrevilge from "../../menuComponents/administrationMenu/UserPrevilge";
import NotificationConfig from "../../menuComponents/administrationMenu/NotificationConfig";
import ShopMaintain from "../../menuComponents/administrationMenu/ShopMaintain";
import UserHistory from "../../menuComponents/reportsMenu/UserHistory";
import UserActionReport from "../../menuComponents/reportsMenu/UserActionReport";
import FullOrdersReport from "../../menuComponents/reportsMenu/FullOrdersReport";
import ShopReport from "../../menuComponents/reportsMenu/ShopReport";
import NotificationReport from "../../menuComponents/reportsMenu/NotificationReport";
import CartList from "../../menuComponents/cartsMenu/CartList";
import RemainderSchedule from "../../menuComponents/cartsMenu/RemainderSchedule";
import AccountPopOver from "./AccountPopOver";
import constants from "../../constants/constant";
import Posts from "../Posts/Posts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinkLayout from "../LinkLayout/LinkLayout";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const { state } = useContext<any>(MyContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [navIndex, setNavIndex] = useState<any>(0);
  const [menuItems, setMenuItems] = useState<any>(navItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.loginDetails);
  const studentData = useSelector((state: any) => state.studentDetails);

  useEffect(() => {
    if (user && user.isLoggedIn) {
      navigate("../dashboard");
    }else {
      navigate("../login");
    }
  }, [navigate, user, dispatch, studentData]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const home = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (state === 0) {
      setMenuItems(subOrders);
    }
    if (state === 1) {
      setMenuItems(subOffers);
    }
    if (state === 2) {
      setMenuItems(subInventary);
    }
    if (state === 3) {
      setMenuItems(subProducts);
    }
    if (state === 4) {
      setMenuItems(subAdministration);
    }
    if (state === 5) {
      setMenuItems(subReports);
    }
    if (state === 6) {
      setMenuItems(subCarts);
    }
  }, [state]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: constants.primary }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Grid
            container
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={8}
            onClick={home}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
          >
            {/* <PopOver /> */}
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
          >
            <AccountPopOver />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((text: any, index: any) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => setNavIndex(index)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                {state === "" && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <ReorderIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && (
                        <ShoppingCartIcon sx={{ color: "#F9546B" }} />
                      )}
                      {index === 3 && (
                        <AccountTreeIcon sx={{ color: "#242C89" }} />
                      )}
                      {index === 4 && (
                        <NotificationsActiveIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && <TrafficIcon sx={{ color: "#FFCC5A" }} />}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 0 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <ReorderIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && <ListAltIcon sx={{ color: "#F9546B" }} />}
                      {index === 3 && <GradingIcon sx={{ color: "#242C89" }} />}
                      {index === 4 && (
                        <CancelPresentationIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && (
                        <InsightsIcon sx={{ color: "#FFCC5A" }} />
                      )}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 1 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <BookmarkAddIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && <LocalOffer sx={{ color: "#F9546B" }} />}
                      {index === 3 && (
                        <SmartButtonIcon sx={{ color: "#242C89" }} />
                      )}
                      {index === 4 && (
                        <NotificationsActiveIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && <TrafficIcon sx={{ color: "#FFCC5A" }} />}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 2 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && <DvrIcon sx={{ color: " #FC7651" }} />}
                      {index === 2 && <SortIcon sx={{ color: "#F9546B" }} />}
                      {index === 3 && (
                        <AccountTreeIcon sx={{ color: "#242C89" }} />
                      )}
                      {index === 4 && (
                        <NotificationsActiveIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && <TrafficIcon sx={{ color: "#FFCC5A" }} />}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 3 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <LibraryAddIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && (
                        <CampaignIcon sx={{ color: "#F9546B" }} />
                      )}
                      {index === 3 && (
                        <AccountTreeIcon sx={{ color: "#242C89" }} />
                      )}
                      {index === 4 && (
                        <NotificationsActiveIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && <TrafficIcon sx={{ color: "#FFCC5A" }} />}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 4 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <AddToQueueIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && (
                        <PersonAddAlt1Icon sx={{ color: "#F9546B" }} />
                      )}
                      {index === 3 && (
                        <AccountTreeIcon sx={{ color: "#242C89" }} />
                      )}
                      {index === 4 && (
                        <ContactMailIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && (
                        <MarkUnreadChatAltIcon sx={{ color: "#FFCC5A" }} />
                      )}
                      {index === 6 && <StoreIcon sx={{ color: "#69D9C9" }} />}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 5 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <RecentActorsIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && (
                        <PersonPinIcon sx={{ color: "#F9546B" }} />
                      )}
                      {index === 3 && <BadgeIcon sx={{ color: "#242C89" }} />}
                      {index === 4 && (
                        <LocalMallIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && (
                        <DoorbellIcon sx={{ color: "#FFCC5A" }} />
                      )}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                {state === 6 && (
                  <>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <HomeIcon sx={{ color: "#009F93" }} />}
                      {index === 1 && (
                        <ShoppingBasketIcon sx={{ color: " #FC7651" }} />
                      )}
                      {index === 2 && (
                        <ScheduleSendIcon sx={{ color: "#F9546B" }} />
                      )}
                      {index === 3 && (
                        <AccountTreeIcon sx={{ color: "#242C89" }} />
                      )}
                      {index === 4 && (
                        <NotificationsActiveIcon sx={{ color: " #FE4B87" }} />
                      )}
                      {index === 5 && <TrafficIcon sx={{ color: "#FFCC5A" }} />}
                      {index === 6 && (
                        <InsightsIcon sx={{ color: "#69D9C9" }} />
                      )}
                      {index === 7 && <RedeemIcon sx={{ color: "#4486D9" }} />}
                    </ListItemIcon>
                  </>
                )}
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        {navIndex === 0 && state === "" && (
          <>
            <Features />
          </>
        )}

        {navIndex === 1 && state === "" && (
          <>
            <Posts />
          </>
        )}
        {navIndex === 2 && state === "" && (
          <>
            <LinkLayout />
          </>
        )}
        {navIndex === 3 && state === "" && (
          <>
            <Status />
          </>
        )}
        {navIndex === 4 && state === "" && (
          <>
            <Notify />
          </>
        )}
        {navIndex === 5 && state === "" && (
          <>
            <Traffic />
          </>
        )}
        {navIndex === 6 && state === "" && <>{/* <Charts /> */}</>}
        {navIndex === 7 && state === "" && (
          <>
            <Coupon />
          </>
        )}
        {/* order management */}
        {navIndex === 0 && state === 0 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 0 && (
          <>
            <NewOrders />
          </>
        )}
        {navIndex === 2 && state === 0 && (
          <>
            <ActiveOrders />
          </>
        )}
        {navIndex === 3 && state === 0 && (
          <>
            <CompletedOrders />
          </>
        )}
        {navIndex === 4 && state === 0 && (
          <>
            <CancelledOrders />
          </>
        )}
        {navIndex === 5 && state === 0 && (
          <>
            <OrdersReport />
          </>
        )}
        {/* offer management */}
        {navIndex === 0 && state === 1 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 1 && (
          <>
            <AddCoupon />
          </>
        )}
        {navIndex === 2 && state === 1 && (
          <>
            <ExistingCoupon />
          </>
        )}
        {navIndex === 3 && state === 1 && (
          <>
            <SpecialOffers />
          </>
        )}
        {/* Inventery menu */}
        {navIndex === 0 && state === 2 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 2 && (
          <>
            <ProductAvailable />
          </>
        )}
        {navIndex === 2 && state === 2 && (
          <>
            <OrderedProductList />
          </>
        )}
        {/* product menu */}
        {navIndex === 0 && state === 3 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 3 && (
          <>
            <StrapiProducts />
          </>
        )}
        {navIndex === 2 && state === 3 && (
          <>
            <ProductCampaign />
          </>
        )}
        {/* Administration */}
        {navIndex === 0 && state === 4 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 4 && (
          <>
            <RoleCreation />
          </>
        )}
        {navIndex === 2 && state === 4 && (
          <>
            <UserCreation />
          </>
        )}
        {navIndex === 3 && state === 4 && (
          <>
            <PagesPrevilage />
          </>
        )}
        {navIndex === 4 && state === 4 && (
          <>
            <UserPrevilge />
          </>
        )}
        {navIndex === 5 && state === 4 && (
          <>
            <NotificationConfig />
          </>
        )}
        {navIndex === 6 && state === 4 && (
          <>
            <ShopMaintain />
          </>
        )}
        {/* Reports */}
        {navIndex === 0 && state === 5 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 5 && (
          <>
            <UserHistory />
          </>
        )}
        {navIndex === 2 && state === 5 && (
          <>
            <UserActionReport />
          </>
        )}
        {navIndex === 3 && state === 5 && (
          <>
            <FullOrdersReport />
          </>
        )}
        {navIndex === 4 && state === 5 && (
          <>
            <ShopReport />
          </>
        )}
        {navIndex === 5 && state === 5 && (
          <>
            <NotificationReport />
          </>
        )}
        {/* Carts */}
        {navIndex === 0 && state === 6 && (
          <>
            <Features />
          </>
        )}
        {navIndex === 1 && state === 6 && (
          <>
            <CartList />
          </>
        )}
        {navIndex === 2 && state === 6 && (
          <>
            <RemainderSchedule />
          </>
        )}
      </Box>
    </Box>
  );
}
